// Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment.
import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { Form, PasswordField, SubmissionStatus, SubmitButton, TextField } from "@hex-insights/forms";
import { serverRequest } from "@hex-insights/roadium.shared";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "../../../Components";
import { AuthenticationContext } from "../../../Contexts";
import { recoverPageInfo } from "../Recover/pageinfo";
import { loginPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

export function LoginPage() {
	useActivePageRegistration(loginPageInfo);

	const onSuccess = React.useCallback(() => {}, []);

	return (
		<Main noNav className={styles["auth-page__main"]}>
			<Column justify="spaced-center" align="center" className={styles["auth-page__content-container"]}>
				<Heading.H1>Login</Heading.H1>

				<div>
					<LoginForm onSuccess={onSuccess} />
				</div>
			</Column>
		</Main>
	);
}

type LoginFormProps = {
	onSuccess: () => void;
};

function LoginForm({ onSuccess }: LoginFormProps) {
	const { formState, onSubmit, linkState } = Authentication.useLogin({
		AuthenticationContext,
		requestLogin,
		onSuccess,
	});

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications noNavigationPrompt>
			<Column justify="spaced-start">
				<If condition={SubmissionStatus.isFailure(formState.submissionStatus)}>
					<Paragraph>{formState.formSubmissionErrors._.join(" ")}</Paragraph>
				</If>

				<TextField formState={formState} name="email" autoFocus />
				<PasswordField formState={formState} name="password" allowVisibility />

				<Row justify="space-between" align="flex-end">
					<InternalLink to={{ pathname: recoverPageInfo.to, state: linkState }}>Forgot Password</InternalLink>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Login
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

function requestLogin(formValues: Authentication.LoginFormValues) {
	return serverRequest("/auth/login", {
		method: "POST",
		body: JSON.stringify(formValues),
	});
}
