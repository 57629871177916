import { Column, Heading } from "@hex-insights/core";
import { Main } from "../../../Components";
import styles from "../authentication.module.css";

export function SplashPage() {
	return (
		<Main noNav className={styles["auth-page__main"]}>
			<Column justify="center" align="center">
				<Heading.H1 noMargin>Roadium Portal</Heading.H1>
			</Column>
		</Main>
	);
}
