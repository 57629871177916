import React from "react";
import { Button, Icon, If, Row, Tooltip, useClickOutListener } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { logoutPageInfo } from "../../../Pages/Authentication/Logout/pageinfo";
import { appVersion, instanceID } from "../../../settings";
import styles from "./styles.module.css";

export function UserButton() {
	const { isTooltipOpen, toggleIsTooltipOpen, closeTooltip } = Tooltip.useToggle();

	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, closeTooltip);

	return (
		<div ref={ref}>
			<Tooltip.Container isControlled isOpen={isTooltipOpen} sideOrder={["right", "bottom"]}>
				<Button variant="tertiary" onClick={toggleIsTooltipOpen} className={styles["user-button"]}>
					<Icon.User className={styles["user-button__icon"]} />
				</Button>

				<Tooltip isInteractable className={styles["user-tooltip"]}>
					<Tooltip.Body>
						<InternalLink to={logoutPageInfo.to} className={styles["user-tooltip__link"]}>
							<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
								<Icon.LogOut />
								Logout
							</Row>
						</InternalLink>
					</Tooltip.Body>
					<Tooltip.Footer>
						<Row justify="space-between" className={styles["user-tooltip__app-info"]}>
							<If condition={appVersion !== ""}>
								<span className={styles["user-tooltip__app-info__version"]}>v{appVersion}</span>
							</If>
							<span className={styles["user-tooltip__app-info__instance-id"]}>{instanceID.split("-")[0]}</span>
						</Row>
					</Tooltip.Footer>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}
