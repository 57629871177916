import React from "react";
import { Navigation, Runners } from "@hex-insights/app-modules";
import { Button, Column, Drawer, Icon, Row, useMaxStackIndex, useMediaQueries } from "@hex-insights/core";
import { ExternalLink, NavLink } from "@hex-insights/router";
import { logoutPageInfo } from "../../Pages/Authentication/Logout/pageinfo";
import { GlobalSearchBar } from "../GlobalSearch";
import { UserButton } from "./UserButton";
import styles from "./styles.module.css";

export function Header() {
	return (
		<React.Fragment>
			<Navigation.SkipToContentLink />
			<Runners.Header Bar={HeaderBar} Drawer={HeaderDrawerController} className={styles["header"] + " no-print"} />
		</React.Fragment>
	);
}

function HeaderBar({ toggleDrawer }: Runners.HeaderBarProps) {
	return (
		<Column justify="space-between" align="center" className={styles["header__bar"]}>
			<Column
				justify="spaced-start"
				align="center"
				verticalSpacing="0.75rem"
				className={styles["header__bar__icon-nav-column"]}
			>
				<Button variant="secondary" onClick={toggleDrawer} className={styles["header__bar__drawer-button"]}>
					<Icon.Menu size="1rem" block />
				</Button>

				<ExternalLink href="https://admin.roadium.myfleat.com" target="" className={styles["header__bar__site-title"]}>
					<div className={styles["image"]}>
						<img
							src="https://cdn.roadium.myfleat.com/images/fleat-logo.png"
							alt="Fleat"
							width={70}
							height={70}
							className="block"
						/>
					</div>
				</ExternalLink>

				<Column
					justify="spaced-end"
					align="center"
					verticalSpacing="0.75rem"
					className={styles["header__bar__nav-column"]}
				>
					<nav className={styles["header__bar__nav"]}>
						<Column justify="spaced-start" align="center" verticalSpacing="1rem">
							<ExternalLink
								href="https://admin.roadium.myfleat.com"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.Home />
									Home
								</Column>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/booking"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.Calendar />
									Booking
								</Column>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/check-in"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.CheckCircle />
									Check In
								</Column>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/vendors"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.ShoppingCart />
									Vendors
								</Column>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/transactions"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.CreditCard />
									Transactions
								</Column>
							</ExternalLink>
							<NavLink to="/" activeClassName={styles["nav-link--location-active"]}>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.ShoppingBag />
									Shoppers
								</Column>
							</NavLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/reports"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.PieChart />
									Reports
								</Column>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/market-management"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
									<Icon.MapPin />
									Market
								</Column>
							</ExternalLink>
						</Column>
					</nav>
				</Column>
			</Column>

			<UserButton />
		</Column>
	);
}

function HeaderDrawerController({ isOpen, onClose }: Runners.HeaderDrawerProps) {
	if (!isOpen) {
		return null;
	}
	return <HeaderDrawer onClose={onClose} />;
}

const desktopMediaQuery = "(min-width: 800px)";
const mediaQueries = [desktopMediaQuery];

function HeaderDrawer({ onClose }: Pick<Runners.HeaderDrawerProps, "onClose">) {
	const media = useMediaQueries(mediaQueries);
	const isOnDesktop = media[desktopMediaQuery];
	const zIndex = useMaxStackIndex();

	React.useEffect(() => {
		if (isOnDesktop) {
			onClose();
		}
	}, [onClose, isOnDesktop]);

	const onNavigateClick = React.useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.ctrlKey || event.metaKey) {
				return;
			}
			onClose();
		},
		[onClose],
	);

	const onSelectResult = React.useCallback(
		(_: any, event: React.MouseEvent | React.KeyboardEvent) => {
			onNavigateClick(event);
		},
		[onNavigateClick],
	);

	return (
		<Drawer location="left" isModal onClose={onClose} zIndex={zIndex} className={styles["header__drawer"]}>
			<Drawer.Body className={styles["header__drawer__body"]}>
				<Column justify="spaced-start" verticalSpacing="0.75rem">
					<div style={{ marginBottom: "2rem" }}>
						<GlobalSearchBar onSelectResult={onSelectResult} className={styles["header__drawer__global-search"]} />
					</div>

					<nav className={styles["header__drawer__nav"]}>
						<Column justify="spaced-start" verticalSpacing="1rem">
							<ExternalLink
								href="https://admin.roadium.myfleat.com"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.Home />
									Home
								</Row>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/booking"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.Calendar />
									Booking
								</Row>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/check-in"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.CheckCircle />
									Check In
								</Row>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/vendors"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.ShoppingCart />
									Vendors
								</Row>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/transactions"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.CreditCard />
									Transactions
								</Row>
							</ExternalLink>
							<NavLink to="/" onClick={onNavigateClick} activeClassName={styles["nav-link--location-active"]}>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.ShoppingBag />
									Shoppers
								</Row>
							</NavLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/reports"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.PieChart />
									Reports
								</Row>
							</ExternalLink>
							<ExternalLink
								href="https://admin.roadium.myfleat.com/market-management"
								target=""
								className={styles["header__external-nav-link"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.MapPin />
									Market
								</Row>
							</ExternalLink>
							<NavLink
								to={logoutPageInfo.to}
								exact={logoutPageInfo.exact}
								onClick={onNavigateClick}
								activeClassName={styles["nav-link--location-active"]}
							>
								<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
									<Icon.LogOut />
									Logout
								</Row>
							</NavLink>
						</Column>
					</nav>
				</Column>
			</Drawer.Body>
		</Drawer>
	);
}
