import { ChildrenProps, ClassNameProps, If, makeClassName, Row, StyleProps } from "@hex-insights/core";
import { NavLink, useScrollReadyOnMount } from "@hex-insights/router";
import { homePageInfo } from "../../Pages/Home/pageinfo";
import { hubsPageInfo } from "../../Pages/Hubs/pageinfo";
import { reportsPageInfo } from "../../Pages/Reports/pageinfo";
import styles from "./styles.module.css";

export type MainProps = {
	/** HTML `id` of the element. */
	id?: string;
	noNav?: boolean;
} & Partial<ClassNameProps> &
	Partial<StyleProps> &
	Partial<ChildrenProps>;

/**
 * Renders an HTML `<main>` element with a default `id` property.
 */
export function Main({ id = "main", noNav = false, className, style, children }: MainProps) {
	useScrollReadyOnMount();

	return (
		<main id={id} className={makeClassName(styles["main"], "scroll-target", className)} style={style}>
			<If condition={!noNav}>
				<div className={styles["main__nav-container"] + " no-print"}>
					<nav className={styles["main__nav"]}>
						<Row justify="spaced-start">
							<NavLink to={homePageInfo.to} exact={homePageInfo.exact}>
								Home
							</NavLink>
							<NavLink to={reportsPageInfo.to} exact={reportsPageInfo.exact}>
								Reports
							</NavLink>
							<NavLink to={hubsPageInfo.to} exact={hubsPageInfo.exact}>
								Hubs
							</NavLink>
						</Row>
					</nav>
				</div>
			</If>

			{children}
		</main>
	);
}
